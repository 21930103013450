


export default {
    'excel_template': {
        id: 'excel_template',
        label: <><b>Excel</b>&nbsp;(Template)</>
    },
    'excel_clinvar': {
        id: 'excel_clinvar',
        label: <><b>Excel</b>&nbsp;(Clinvar)</>
    },
    'phenopacket': {
        id: 'phenopacket',
        label: <><b>Phenopackets</b></>
    },
    // 'dummy': {
    //     id: 'dummy',
    //     label: <><b>BLABLS</b></>
    // }
}
