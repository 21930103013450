import './Footer.scss'

function Footer() {

    return(
        <div className="page-footer">
            v12.2023_1
        </div>
    )
}

export default Footer

